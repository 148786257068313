<template>
    <div>
        <v-container fluid>
            <p>Manage the jukeboxes you control. You can start a new jukebox or launch and edit an existing jukebox.</p>
            <v-btn block :to="'/jukebox/add'" color="primary">Create a Jukebox</v-btn>
        </v-container>
        <v-list class="transparent" v-if="jukeboxes">
            <template v-for="jukebox in jukeboxes">
                <v-list-item :to="'/jukebox/details/'+jukebox.jukebox_id">
                    <v-list-item-title >
                        <router-link :to="'/jukebox/details/'+jukebox.jukebox_id" class="white--text">{{ jukebox.title }}</router-link>
                        <v-chip color="primary" x-small v-if="jukebox.status == '1'">online</v-chip>
                    </v-list-item-title>
                    <v-list-item-action>
                        <v-btn icon :to="'/jukebox/player/'+jukebox.jukebox_id">
                            <v-icon color="primary">mdi-play-circle</v-icon>
                        </v-btn>
                    </v-list-item-action>
                    <v-list-item-action >
                        <v-menu>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on"  @click.native.prevent>
                                    <v-icon color="primary">mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list color="primary">
                                <v-list-item :to="'/jukebox/details/'+jukebox.jukebox_id">
                                    <v-list-item-icon>
                                        <v-icon>mdi-eye</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>View Jukebox</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item :to="'/jukebox/edit/'+jukebox.jukebox_id">
                                    <v-list-item-icon>
                                        <v-icon>mdi-pencil</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Edit Jukebox</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item :to="'/jukebox/history/'+jukebox.jukebox_id">
                                    <v-list-item-icon>
                                        <v-icon>mdi-music</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Edit History</v-list-item-title>
                                </v-list-item>
                                <v-divider></v-divider>
                                <v-list-item @click="showDeleteModal = true; deleteId = jukebox.jukebox_id">
                                    <v-list-item-icon>
                                        <v-icon>mdi-delete</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title>Delete Jukebox</v-list-item-title>
                                </v-list-item>
                            </v-list>

                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
            </template>
        </v-list>
        <v-dialog v-model="showDeleteModal">
            <v-card>
                <v-card-title>Delete Jukebox?</v-card-title>

                <v-card-text>
                    Are you sure you want to delete this jukebox?
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="showDeleteModal = false" color="secondary">Close</v-btn>
                    <v-btn @click="deleteJukebox" color="error" >Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "Jukeboxes",
        data: () => {
            return {
                jukeboxes: [],
                showDeleteModal: false,
                deleteId: null
            }
        },
        methods: {
            deleteJukebox() {

                this.$http.post('/api/jukebox/delete', {
                    jukebox_id: this.deleteId
                })
                    .then(() => {
                        var index = this.jukeboxes.findIndex(jukebox => jukebox.jukebox_id === this.deleteId);
                        this.jukeboxes.splice(index,1);

                        this.deleteId = null;
                        this.showDeleteModal = false;

                    });

            }

        },
        mounted() {
            this.$store.data.loading = true;
            this.$http.get('/api/users/jukeboxes')
            .then(response => {
                this.jukeboxes = response.data;
                this.$store.data.loading = false;

            });
        }
    }
</script>
