<template>
    <div class="pb-15 text-center" style="height:100%;position:absolute;top:0;left:0;right:0;">
        <div id="linkPlayer" style="max-width:100%"></div>
    </div>
</template>

<script>
export default {
    name: "LinkPlayer",
    data: () => {
        return {
            jukeboxId: null,
            queueInterval: null,
            defaultVideoId: 'J4Mi0JnGfeg',
            player: null,
            mobileFirstPlay: false,
            queueResponse: {},
            playing: false,
            socket: null,
        }
    },
    watch: {
        $route(to,from) {
            if(to.name === 'link-player') {
                if(!this.playing) {
                    this.jukeboxId = this.$route.params.id;
                    this.startPlayer();
                } else {
                    if(this.jukeboxId !== this.$route.params.id) {
                        this.jukeboxId = this.$route.params.id;
                        this.player.destroy();
                        this.tag.remove();
                        this.startPlayer();
                    }
                }
            }
        },
    },
    methods: {
        startPlayer() {
            var self = this;


            this.$http.post('/api/jukebox/checkIn', {
                'jukebox_id': this.jukeboxId
            })
                .then( response => {
                    if(response.data.status === 'success') {
                        this.$store.data.jukeboxId = this.jukeboxId;
                    }

                    this.$http.get('/api/tracks/tracks', {
                        limit: 1,
                        type: 'queue'
                    })
                    .then(playResponse => {

                        this.queueResponse = playResponse.data.last_played;

                    });





                    // 2. This code loads the IFrame Player API code asynchronously.
                    if (typeof this.tag === 'undefined') {
                        // no script tag, lets make it

                        var tag = document.createElement('script');
                        tag.src = "https://www.youtube.com/iframe_api";

                        this.tag = tag;
                        var firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                    } else {
                        // tag already exists, so we just need to ready up
                        onYouTubeIframeAPIReady();
                    }

                    // 3. This function creates an <iframe> (and YouTube player)
                    //    after the API code downloads.
                    window.onYouTubeIframeAPIReady = function() {
                        if (typeof window.orientation !== 'undefined') {
                            self.mobileFirstPlay = true;
                        }

                        self.playing = true;
                        self.player = new YT.Player('linkPlayer', {
                            height: '100%',
                            width: '100%',
                            playerVars: {
                                'playsinline': 1
                            },
                            events: {
                                'onReady': self.onPlayerReady,
                                'onStateChange': self.onPlayerStateChange,
                                'onError': self.onPlayerError
                            }
                        });
                    }


                });

        },

        onPlayerReady(event) {
            // 4. The API will call this function when the video player is ready.
            this.startSocket();
            //this.playTrack(this.queueResponse.video_id);

        },

        onPlayerError(event) {


        },
        onPlayerStateChange(event) {
            /*
            -1 (unstarted)
            0 (ended)
            1 (playing)
            2 (paused)
            3 (buffering)
            5 (video cued).
            */
            switch (event.data) {
                case -1:
                    break;
                case 0:
                    break;
                case 1:
                    // playing
                    this.mobileFirstPlay = false;
                    break;
                case 2:
                    break;
                case 3:
                    break;
                case 5:
                    this.player.playVideo();
                    break;
            }
        },
        playTrack(track, time) {
            var videoId = null;

            if (track == null) {
                this.player.cueVideoById(this.defaultVideoId);
                videoId = this.defaultVideoId;
                if (this.mobileFirstPlay === false){
                    this.player.playVideo();
                }
            } else {
                if(typeof time !== 'undefined' ) {
                    this.player.cueVideoById(track, time);
                } else {
                    this.player.cueVideoById(track);
                }
                videoId = track;
                if (this.mobileFirstPlay === false){
                    this.player.playVideo();
                }
            }
        },
        startSocket() {

            this.socket = new WebSocket(this.socketUrl);

            this.socket.onmessage = (message)  => {
                var data = JSON.parse(message.data);

                switch(data.type) {
                    case 'playTrack':
                        if(typeof data.time !== 'undefined') {
                            this.playTrack(data.trackId, data.time);
                        } else {
                            this.playTrack(data.trackId);

                        }
                        break;

                }
            };

            this.socket.onopen = (event) => {
                var obj = {
                    'type': 'join',
                    'jukeboxId': this.jukeboxId
                }

                this.socket.send(JSON.stringify(obj));

            };




        },
    },
    computed: {
        socketUrl() {
            return SOCKET_URL;
        }
    },
    mounted() {
    }

}
</script>
