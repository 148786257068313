<template>
    <div class="pb-15">

        <v-img :aspect-ratio="16/9" :src="jukebox.image_url" max-height="300">
            <v-row align="end" class="white--text px-2 fill-height">
                <v-col class="lightbox">
                    <h2>{{ jukebox.title }}</h2>
                </v-col>
            </v-row>
        </v-img>
        <v-container fluid style="position:relative" class="pt-5">
            <v-btn v-if="jukebox.owner"
                   color="primary"
                   dark
                   small
                   absolute
                   top
                   right
                   fab
                   :to="'/jukebox/player/'+jukebox.jukebox_id"
                >
                <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn v-if="!jukebox.owner && checkedInHere && parseInt(jukebox.link_play) === 1"
                   color="primary"
                   dark
                   small
                   absolute
                   top
                   right
                   fab
                   :to="'/jukebox/remoteplay/'+jukebox.jukebox_id+'-'+jukebox.link_play_hash"
            >
                <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-btn block v-if="jukebox.status == '2'" class="mb-3" disabled>Offline</v-btn>
            <div v-if="checkedInHere">
                <v-btn disabled block color="primary" outlined class="mb-3">Checked-In</v-btn>
                <v-btn to="/search" color="primary" block>Find a Track</v-btn>
            </div>
            <div v-if="!checkedInHere">
                <div v-if="jukebox.password_len > 0">
                    <v-text-field label="Enter Jukebox Password" v-model="password" :error="checkInError"></v-text-field>
                </div>
                <v-btn color="primary" block v-if="showCheckInBtn" @click="checkIn" v-text="!checkingIn ? 'Check-In' : 'Checking In...'" :disabled="checkingIn"></v-btn>
            </div>

            <v-row>
                <v-col>
                    {{ jukebox.description }}
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <v-btn color="primary" icon @click="showQrModal = true"><v-icon>mdi-qrcode</v-icon></v-btn>
                    <v-btn v-if="jukebox.website" :href="'http://'+jukebox.website" color="primary" target="_blank" icon><v-icon>mdi-earth</v-icon></v-btn>
                    <v-btn v-if="jukebox.twitter" :href="'https://twitter.com/'+jukebox.twitter" color="primary" target="_blank" icon><v-icon>mdi-twitter</v-icon></v-btn>
                    <v-btn v-if="jukebox.facebook" :href="'https://facebook.com/'+jukebox.facebook" color="primary" target="_blank" icon><v-icon>mdi-facebook</v-icon></v-btn>

                </v-col>

            </v-row>

            <v-row>
                <v-col>
                    <h3 class="font-weight-light">Who's Here</h3>
                    <span class="text--secondary" v-if="listeners.length === 0">Nobody is checked-in</span>

                    <router-link v-for="user in listeners" :key="user.id" :to="'/user/'+user.username">
                        <v-avatar >
                            <v-img :src="user.avatar" :title="user.username"></v-img>
                        </v-avatar>
                    </router-link>
                </v-col>
            </v-row>

            <v-row>
                <v-col>
                    <h3 class="font-weight-light">Last Played Track</h3>
                </v-col>
            </v-row>
        </v-container>

        <track-item :track="lastPlayed" :key="lastPlayed.video_id" v-if="lastPlayed"></track-item>

        <v-container fluid>
            <v-row>
                <v-col>
                    <h3 class="font-weight-light">Top Tracks Played</h3>
                </v-col>
            </v-row>
        </v-container>

        <div :key="index" v-for="(trackItem,index) in topTracks">
            <track-item :track="trackItem"></track-item>
            <v-divider v-if="index !== Object.keys(topTracks).length - 1"></v-divider>
        </div>

        <v-dialog
            v-model="showQrModal"
            hide-overlay
            max-width="300"
        >
            <v-card light>
                <v-card-title
                >
                    <v-toolbar-title>{{ jukebox.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        icon
                        @click="showQrModal = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                    Scan the QR code to go to the jukebox.
                </v-card-text>
                <v-card-text class="text-center">
                    <qrcode-vue :value="this.currentUrl" :size="200"></qrcode-vue>
                </v-card-text>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>

    import trackItem from "../components/track.vue";
    import QrcodeVue from "qrcode.vue";

    export default {
        name: "Jukebox",
        components: {
            trackItem,
            QrcodeVue
        },
        data: () => {
            return {
                jukeboxId: null,
                jukebox: {},
                lastPlayed: null,
                listeners: [],
                topTracks: [],
                checkedInHere: false,
                checkingIn: false,
                password: '',
                checkInError: false,
                showQrModal: false

            }
        },
        methods: {
            checkIn() {
                var apiData = {
                    jukebox_id: this.jukeboxId
                };

                this.checkingIn = true;
                this.checkInError = false;

                if (this.jukebox.password_len > 0) {
                    apiData.password = this.password;
                }

                this.$http.post('/api/jukebox/checkIn', apiData)
                    .then(response => {
                        if (response.data.status === 'success') {
                            this.checkedInHere = true;
                            this.$store.data.jukeboxId = this.jukeboxId;
                        } else {
                            this.checkingIn = false;
                            this.password = '';
                            this.checkInError = true;

                        }

                    });

            }
        },
        computed: {
            showCheckInBtn() {
                if (this.jukebox.status !== '1') {
                    if(this.jukebox.owner === true && !this.checkedInHere) {
                        return true;
                    }
                    return false;
                } else if (this.checkedInHere) {
                    return false;
                }

                return true;
            },
            currentUrl() {
                return window.location.href;

            }

        },
        mounted() {
            this.$store.data.loading = true;
            this.jukeboxId = this.$route.params.id;
            Promise.all([
                this.$http.get('/api/jukebox/item', {
                    jukebox_id: this.jukeboxId
                }),
                this.$http.get('/api/users/isCheckedIn'),
            ])
            .then(response => {

                this.jukebox = response[0].data.jukebox;
                this.lastPlayed = response[0].data.last_played_track;
                this.listeners = response[0].data.listeners;
                this.topTracks = response[0].data.top_tracks;

                if (response[1].status === true && response[1].jukebox_id === this.jukeboxId) {
                    this.checkedInHere = true;
                }
                this.$store.data.loading = false;

            });
        }

    }
</script>
