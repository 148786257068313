import Vue from 'vue'
import App from './App.vue'
import appStore from "./appStore";
import api from "./api";
import './registerServiceWorker'
import router from './router'
//import vuetify from './plugins/vuetify'
import vuetify from "./vuetify";
import VueGtag from "vue-gtag";
import "./styles/style.scss";
import VueRouter from "vue-router"; // todo might not be getting loaded including variables.scss

Vue.config.productionTip = false

Vue.use(api);
Vue.use(VueGtag, {
    pageTrackerTemplate(to) {
        return {
            page_title: document.title,
            page_path: to.path,
        }
    },
    config: { id: "G-GJYTGM5WXE" },
}, router);

Vue.prototype.$http = api;
Vue.prototype.$store = appStore;

router.beforeEach((to, from, next) => {

    navigator.serviceWorker.getRegistration(`${process.env.BASE_URL}service-worker.js`).then(reg => {
        // check for updates to the service working on change of route
        reg.update();
    });

    if(to.name !== 'login' && to.name !== 'register' && to.name !== 'logout' && to.name !== 'forgot-password') {
        api.get('/api/users/checkSession')
            .then(response => {
                if (response.data.session === false){
                    appStore.data.isUser = false;
                    next({
                        name: 'login',
                        query: { redirect: to.fullPath }
                    });
                } else {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('username', response.data.username);
                    appStore.data.isUser = true;
                    appStore.data.jukeboxId = response.data.jukebox_id;
                    appStore.data.username = response.data.username;
                    appStore.methods.loadLocation();
                    next();
                }
            });

    } else {
        next();
    }
});



function heartbeat () {
    var routeName = router.currentRoute.name;
    if (routeName !== 'login' && routeName !== 'register' && routeName !== 'password') {
        //check login
        api.get('/api/users/checkSession')
            .then(response => {
                if (response.data.session === false){
                    appStore.data.isUser = false;
                    router.push({
                        name: 'login',
                    });
                } else {
                    localStorage.setItem('token', response.data.token);
                    localStorage.setItem('username', response.data.username);
                    appStore.methods.loadLocation();
                    appStore.data.isUser = true;
                    appStore.data.jukeboxId = response.data.jukebox_id;
                    appStore.data.username = response.data.username;
                }
            });
    }
    window.clearTimeout(heartbeatTimeout);
    heartbeatTimeout = window.setTimeout(heartbeat, 60000);
}
var heartbeatTimeout = window.setTimeout(heartbeat, 60000);

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
