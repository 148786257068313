<template>
    <div>
        <v-container fluid style="position:sticky;top: 0;z-index:100;">
            <v-text-field
                v-model="keyword"
                append-icon="mdi-crosshairs-gps"
                label="Search by Jukebox Name"
                type="text"
                solo
                hide-details
                class="elevation-10"
                color="none"
                @click:append="getLocation"
                @keyup="getJukeboxes"
            ></v-text-field>
        </v-container>
        <div>
            <location-button :text="'Find a Jukebox Near Me'" :showBtn="false" @success="getJukeboxes" ref="locationBtn"></location-button>

            <v-list two-line class="transparent">
                <template v-for="jukebox in jukeboxes">
                    <v-list-item :to="'/jukebox/details/'+jukebox.jukebox_id" >
                        <v-list-item-avatar tile v-if="parseInt(jukebox.status) === 1" size="48">
                            <v-badge
                                avatar
                                bordered
                                overlap
                                dot
                                >
                                <template v-slot:badge><span></span></template>
                                <v-avatar size="48" rounded>
                                    <v-img :src="jukebox.image_url"></v-img>
                                </v-avatar>
                            </v-badge>
                        </v-list-item-avatar>

                        <v-list-item-avatar rounded v-if="parseInt(jukebox.status) !== 1" size="48">
                            <v-img :src="jukebox.image_url"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title :class="parseInt(jukebox.status) === 1 ? 'primary--text' : 'text--disabled'">{{ jukebox.title }}</v-list-item-title>
                            <v-list-item-subtitle>{{ jukebox.description }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action v-if="parseInt(jukebox.link_play) === 1">
                            <v-chip small color="primary">Remote Play</v-chip>
                        </v-list-item-action>
                        <v-list-item-action>
                            <v-list-item-action-text v-if="jukebox.distance">{{ parseFloat(jukebox.distance).toFixed(1) }}mi</v-list-item-action-text>
                            <!-- <v-icon color="grey">mdi-star-outline</v-icon> -->
                        </v-list-item-action>

                    </v-list-item>
                    <v-divider></v-divider>

                </template>
                <v-list-item to="/jukeboxes/my" >
                    <v-list-item-avatar rounded size="48">
                        <v-img :src="'https://www.jukeboxstar.com/assets/img/default-venue.jpg'"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">Create a Jukebox</v-list-item-title>
                        <v-list-item-subtitle>Start your own jukebox and play music.</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon class="primary--text">mdi-plus-circle</v-icon>
                    </v-list-item-action>

                </v-list-item>
            </v-list>
        </div>

    </div>
</template>

<script>
import locationButton from "./components/locationButton.vue";
export default {
    name: "Jukeboxes",
    components: {
        locationButton
    },
    data: () => {
        return {
            jukeboxes: [],
            keyword: null
        }
    },
    methods: {
        getLocation() {
            this.$refs.locationBtn.clicked();
        },
        getJukeboxes() {
            this.$store.data.loading = true;
            var endpoint = 'search';

            if(this.$store.data.haveLocation && this.search === null) {
                endpoint = 'geoSearch';
            }

            this.$http.get('/api/jukebox/'+endpoint, {
                lat: this.$store.data.lat,
                lng: this.$store.data.long,
                keyword: this.keyword
            })
                .then(geoResponse => {
                    this.$store.data.loading = false;
                    this.jukeboxes = geoResponse.data.results;
                });

        }


    },
    mounted() {
        this.$store.data.loading = true;

        if(this.$store.data.haveLocation) {
            this.getJukeboxes();
        } else {
            this.$http.get('/api/jukebox/active')
                .then(geoResponse => {
                    this.$store.data.loading = false;
                    this.jukeboxes = geoResponse.data.results;
                });
        }

    }

}
</script>
