import Vue from 'vue'
import VueRouter from 'vue-router'

import HomePage from "./../views/HomePage.vue";
import LoginPage from "./../views/LoginPage.vue";
import RegisterPage from "./../views/RegisterPage.vue";
import LogoutPage from "./../views/LogoutPage.vue";
import ForgotPasswordPage from "./../views/ForgotPasswordPage.vue";
import MorePage from "./../views/MorePage.vue";
import AboutPage from "./../views/AboutPage.vue";

import QueuePage from "./../views/QueuePage.vue";

import JukeboxesPage from "./../views/JukeboxesPage.vue";
import JukeboxesSearchPage from "./../views/JukeboxesSearchPage.vue";
import JukeboxesMyPage from "./../views/JukeboxesMyPage.vue";
import JukeboxPage from "./../views/JukeboxPage.vue";
import JukeboxAddPage from "./../views/JukeboxAddPage.vue";
import JukeboxEditPage from "./../views/JukeboxEditPage.vue";
import JukeboxHistoryPage from "./../views/JukeboxHistoryPage.vue";
import JukeboxPlayerPage from "./../views/JukeboxPlayerPage.vue";

import MusicPage from "./../views/MusicPage.vue";
import MusicBrowsePage from "./../views/MusicBrowsePage.vue";
import MusicResultsPage from "./../views/MusicResultsPage.vue";
import PlaylistAddPage from "./../views/PlaylistAddPage.vue";
import PlaylistEditPage from "./../views/PlaylistEditPage.vue";

import UserPage from "./../views/UserPage.vue";
import EditProfilePage from "./../views/EditProfilePage.vue";
import ChangePasswordPage from "./../views/ChangePasswordPage.vue";

Vue.use(VueRouter)

const routes = [
    { path: '/', component: HomePage, name: 'home'},
    { path: '/search', component: MusicPage, name: 'music',
        children: [
            { path: '', component: MusicBrowsePage, name: 'music-browse'},
            { path: 'playlistAdd/:id', component: PlaylistAddPage, name: 'playlist-add'},
            { path: 'playlistEdit/:jukeboxId/:id', component: PlaylistEditPage, name: 'playlist-edit'},
            { path: ':type/:id', component: MusicResultsPage, name: 'music-search'}
        ]
    },
    { path: '/login', component: LoginPage, name: 'login'},
    { path: '/logout', component: LogoutPage, name: 'logout'},
    { path: '/register', component: RegisterPage, name: 'register'},
    { path: '/forgot-password', component: ForgotPasswordPage, name: 'forgot-password'},
    { path: '/queue', component: QueuePage, name: 'queue'},
    { path: '/jukeboxes', component: JukeboxesPage, name: 'jukeboxes',
        children: [
            { path: '', component: JukeboxesSearchPage, name: 'jukeboxes-search'},
            { path: 'my', component: JukeboxesMyPage, name: 'jukeboxes-my'}

        ]
    },
    { path: '/jukebox/details/:id', component: JukeboxPage, name: 'jukebox'},
    { path: '/jukebox/edit/:id', component: JukeboxEditPage, name: 'jukebox-edit'},
    { path: '/jukebox/history/:id', component: JukeboxHistoryPage, name: 'jukebox-history'},
    { path: '/jukebox/player/:id', component: JukeboxPlayerPage, name: 'jukebox-player'},
    { path: '/jukebox/remoteplay/:id', component: JukeboxPlayerPage, name: 'link-player'},
    { path: '/jukebox/add', component: JukeboxAddPage, name: 'jukebox-add'},
    { path: '/user/editProfile', component: EditProfilePage, name: 'edit-profile'},
    { path: '/user/changePassword', component: ChangePasswordPage, name: 'change-password'},
    { path: '/user/:username', component: UserPage, name: 'user'},
    { path: '/more', component: MorePage, name: 'more'},
    { path: '/more/about', component: AboutPage, name: 'about'}
]

const router = new VueRouter({
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
