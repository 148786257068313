<template>
    <v-container fluid fill-height >
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4" >
                <v-img src="/assets/img/logo-lg.png" class="mb-5"/>
                        <v-form @submit="login" @submit.prevent>
                            <v-alert v-show="error" type="error" >{{ errorMsg }}</v-alert>
                            <v-text-field label="Username or Email" v-model="username" autofocus></v-text-field>
                            <v-text-field type="password" label="Password" v-model="password"></v-text-field>
                            <v-btn type="submit" block color="primary">Login</v-btn>
                        </v-form>
                        <v-row>
                            <v-col >
                                <router-link to="/register">Create an Account</router-link>
                            </v-col>
                            <v-col class="text-right">
                                <router-link to="/forgot-password">Forgot Password?</router-link>
                            </v-col>
                        </v-row>

                        <div class="text-center">
                            <small>By using Jukebox Star, you are agreeing to our <a href="/blog/privacy-policy/" target="_blank">Privacy Policy</a></small>
                        </div>

            </v-col>
        </v-row>

    </v-container>
</template>

<script>
    import appStore from "../appStore";

    export default {
        name: "Login",
        data: () => {
            return {
                username: '',
                password: '',
                error: false,
                errorMsg: null
            }
        },
        methods: {
            login() {
                this.errorMsg = null;
                this.error = false;

                this.$http.post('/api/users/login', {
                    username: this.username,
                    password: this.password

                })
                    .then(response => {
                        if (response.data.status === 'error') {
                            this.$store.data.isUser = false;
                            this.errorMsg = response.data.errors;
                            this.error = true;
                        } else if (response.data.status === 'success'){
                            localStorage.setItem('token', response.data.token)
                            localStorage.setItem('username', response.data.username);
                            this.$store.data.isUser = true;
                            this.$store.data.username = response.data.username;
                            if(this.$route.query.redirect) {
                                this.$router.push({path: this.$route.query.redirect});
                            } else {
                                this.$router.push({path: '/'});
                            }

                        }
                    })
                    .catch((e) => {
                    });
            }

        }
    }
</script>
