<template>
    <div class="pb-15">

        <div style="position:relative;overflow:hidden;" class="pa-5 text-center">
            <div style="position:relative;z-index:2;">
                <v-avatar size="150" class="elevation-10">
                    <v-img :src="user.avatar" ></v-img>
                </v-avatar>
                <h2 class="font-weight-thin">{{ user.username }}</h2>
                <v-btn
                    v-if="user.twitter"
                    :href="'https://twitter.com/'+user.twitter"
                    color="primary"
                    target="_blank"
                    fab
                    small
                    elevation="2"><v-icon>mdi-twitter</v-icon>
                </v-btn>
                <v-btn
                    v-if="user.facebook"
                    :href="'https://facebook.com/'+user.facebook"
                    color="primary"
                    target="_blank"
                    fab
                    small
                    elevation="2"><v-icon>mdi-facebook</v-icon>
                </v-btn>
            </div>
            <div :style="'background-image: url('+user.avatar+')'" class="blur-bg-img" ></div>

        </div>
        <v-container fluid style="position:relative" class="pt-5">
            <v-menu v-if="this.isSelf">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                           color="primary"
                           dark
                           small
                           absolute
                           top
                           right
                           fab
                           v-bind="attrs"
                           v-on="on"
                    >
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list color="primary">
                    <v-list-item :to="'/user/editProfile'">
                        <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Edit Profile</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="'/user/changePassword'">
                        <v-list-item-icon>
                            <v-icon>mdi-lock</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Change Password</v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item :to="'/logout'">
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            <v-row>
                <v-col>
                    {{ user.bio }}
                </v-col>
            </v-row>


            <v-row>
                <v-col>
                    <v-card class="secondary" elevation="2">
                        <v-card-text class="text-center">
                            <h1 class="mb-1">{{ user.tracks_played }}</h1>
                            Tracks Played
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col>
                    <v-card class="secondary" elevation="2">
                        <v-card-text class="text-center">
                            <h1 class="mb-1">{{ user.checkins }}</h1>
                            Check-ins
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-row>



            <v-row>
                <v-col>
                    <h3 class="font-weight-light">Most Played Tracks</h3>
                </v-col>
            </v-row>
        </v-container>

        <div :key="index" v-for="(trackItem,index) in topTracks">
            <track-item :track="trackItem"></track-item>
            <v-divider v-if="index !== Object.keys(topTracks).length - 1"></v-divider>
        </div>

    </div>
</template>

<script>

import trackItem from "../components/track.vue";

export default {
    name: "User",
    components: {
        trackItem
    },
    data: () => {
        return {
            user: {},
            topTracks: [],
            isSelf: false
        }
    },
    mounted() {

        this.$store.data.loading = true;

        this.$http.get('/api/users/profile', {
            'username': this.$route.params.username
        })
            .then(response => {

                this.topTracks = response.data.user.top_tracks;
                this.user = response.data.user;
                this.isSelf = response.data.user.self;

                this.$store.data.loading = false;

            });
    }

}
</script>
