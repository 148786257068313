<template>
    <div class="pb-15">
        <v-snackbar v-model="showError" top color="error" multi-line timeout="10000">
            <span v-html="errorHtml"></span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showError = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <div v-if="!success">
            <v-container>
                <v-row>
                    <v-col cols="8">
                        <h2>Create a Jukebox</h2>
                    </v-col>
                    <v-col class="text-right">
                        <v-btn class="secondary" :to="'/jukeboxes/my'">Back</v-btn>
                    </v-col>

                </v-row>
                <p>Creating a jukebox is quick, easy and free.</p>
            </v-container>
            <v-form @submit="create" @submit.prevent>
                <v-stepper v-model="currentStep" vertical flat class="transparent elevation-0">
                    <v-stepper-step :complete="currentStep > 1" step="1">
                        Jukebox Name & Location
                        <small class="mt-1">Required to help users find your jukebox.</small>
                    </v-stepper-step>
                    <v-stepper-content step="1">
                        <v-text-field label="Jukebox Name" placeholder="ie: Joe Blogg's Place" v-model="title"></v-text-field>
                        <location-button show-btn="true" text="Get My Location" @success="setLocation"></location-button>

                        <v-btn @click="validateStep" color="primary" class="mt-3" :disabled="!validStep1">Next</v-btn>
                    </v-stepper-content>

                    <v-stepper-step :complete="currentStep > 2" step="2">
                        Jukebox Details (optional)
                        <small class="mt-1">Customise your jukebox's page.</small>
                    </v-stepper-step>
                    <v-stepper-content step="2">
                        <v-textarea label="Description" placeholder="(optional)" v-model="description"></v-textarea>

                        <v-file-input label="Cover Image" v-model="coverImage" prepend-icon="mdi-image" placeholder="Choose a Cover Image" hint="JPEG only" persistent-hint class="mb-3"></v-file-input>

                        <v-text-field label="Website" prefix="https://" placeholder=" " v-model="website"></v-text-field>

                        <v-text-field label="Twitter" prefix="https://twitter.com/" placeholder=" " v-model="twitter"></v-text-field>

                        <v-text-field label="Facebook" prefix="https://facebook.com/" placeholder=" " v-model="facebook"></v-text-field>

                        <v-btn color="secondary" @click="currentStep = 1">Back</v-btn>
                        <v-btn color="primary" @click="validateStep">Next</v-btn>
                    </v-stepper-content>

                    <v-stepper-step step="3">
                        Jukebox Settings
                        <small class="mt-1">Choose how your jukebox works.</small>
                    </v-stepper-step>
                    <v-stepper-content step="3">
                        <v-text-field class="mb-3" label="Password Protect" placeholder="(optional)" hint="Only users with the password can add tracks." persistent-hint v-model="password"></v-text-field>
                        <v-select
                            label="Empty Queue Setting"
                            hint="Choose which tracks are played when the queue is empty."
                            persistent-hint
                            :items="queueSettingItems"
                            v-model="emptySetting"
                            class="mb-3"
                        >

                        </v-select>

                        <v-select
                            label="Track Selection Setting"
                            hint="Choose which tracks can be selected to play."
                            persistent-hint
                            class="mb-3"
                            :items="trackSettingItems"
                            v-model="trackSetting"
                        >
                        </v-select>

                        <v-select
                            label="User Queue Limit"
                            hint="Limit how many tracks a user can have in the queue."
                            persistent-hint
                            class="mb-3"
                            :items="userLimitItems"
                            v-model="userQueueLimit"
                        >
                        </v-select>

                        <v-switch
                            v-model="linkPlay"
                            :label="'Remote Play - Allow users to listen to this jukebox from other locations (jukebox must be playing)'"
                            inset
                        ></v-switch>

                        <v-switch
                            v-model="shufflePlay"
                            :label="'Shuffle Queue - Play queued songs in shuffle mode'"
                            inset
                        ></v-switch>

                        <v-switch
                            v-model="userQueueDelete"
                            :label="'Delete from Queue - Allow users to remove their queued songs from the jukebox queue'"
                            inset
                        ></v-switch>

                        <v-btn color="secondary" @click="currentStep = 2">Back</v-btn>
                        <v-btn type="submit" color="primary">Create Jukebox</v-btn>
                    </v-stepper-content>

                </v-stepper>
            </v-form>
        </div>

        <v-fade-transition>
            <v-container v-if="success" class="text-center" fluid>
                <v-icon x-large color="primary" style="font-size:3rem">mdi-check</v-icon>
                <h2>Jukebox Created!</h2>
                <p>Well done! You've created a jukebox.</p>
                <v-btn block color="primary" class="mb-3" :to="'/jukebox/player/'+jukeboxId">Start Jukebox Player</v-btn>
                <v-btn block color="primary" :to="'/jukebox/details/'+jukeboxId">View Jukebox</v-btn>
            </v-container>
        </v-fade-transition>
    </div>
</template>

<script>
import locationButton from "./components/locationButton.vue";

export default {
    name: "JukeboxAddPage",
    components: {
        locationButton
    },
    data: () => {
        return {
            success: false,
            showError: false,
            errorHtml: '',
            currentStep: 1,
            title: null,
            lat: null,
            long: null,
            description: '',
            coverImage: null,
            website: '',
            twitter: '',
            facebook: '',
            password: '',
            emptySetting: 0,
            trackSetting: 0,
            linkPlay: 0,
            userQueueLimit: 0,
            shufflePlay: 0,
            userQueueDelete: 1,
            jukeboxId: null,
            queueSettingItems: [
                {
                    text: 'Play Nothing',
                    value: 0
                },
                {
                    text: 'This Jukebox\'s History',
                    value: 1
                },
                {
                    text: 'Entire Jukebox Star Database',
                    value: 2
                }
            ],
            trackSettingItems: [
                {
                    text: 'Any Song',
                    value: 0
                },
                {
                    text: 'This Jukebox\'s History',
                    value: 1
                }
            ],
            userLimitItems: [
                {
                    text: 'No Queue Limit',
                    value: 0
                },
                {
                    text: '1 Track',
                    value: 1
                },
                {
                    text: '2 Tracks',
                    value: 2
                },
                {
                    text: '3 Tracks',
                    value: 3
                },
                {
                    text: '4 Tracks',
                    value: 4
                },
                {
                    text: '5 Tracks',
                    value: 5
                },
                {
                    text: '6 Tracks',
                    value: 6
                },
                {
                    text: '7 Tracks',
                    value: 7
                },
                {
                    text: '8 Tracks',
                    value: 8
                },
                {
                    text: '9 Tracks',
                    value: 9
                },
                {
                    text: '10 Tracks',
                    value: 10
                },
            ]
        }
    },

    computed: {
        validStep1() {
            if (this.title && this.lat && this.long) {
                return true;
            }
            return false;

        }

    },

    methods: {
        validateStep() {
            switch (this.currentStep) {
                case 1:
                    this.currentStep = 2;

                    break;
                case 2:
                    this.currentStep = 3;

                    break;


            }

        },
        setLocation () {
            this.lat = this.$store.data.lat;
            this.long = this.$store.data.long;
        },
        create() {
            var apiData = {
                'title': this.title,
                'geo': this.lat+','+this.long,
                'description': this.description,
                'image': this.coverImage,
                'website': this.website,
                'twitter': this.twitter,
                'facebook': this.facebook,
                'empty_setting': this.emptySetting,
                'selection_setting': this.trackSetting,
                'link_play': this.linkPlay ? 1 : 0,
                'shuffle_play': this.shufflePlay ? 1 : 0,
                'password': this.password,
                'user_queue_limit': this.userQueueLimit,
                'user_queue_delete': this.userQueueDelete ? 1 : 0,
            };


            this.$http.post('/api/jukebox/add', apiData)
                .then(response => {

                    if(response.data.status === 'error') {
                        this.showError = true;
                        this.errorHtml = response.data.errors;

                        if(response.data.errors.includes('name')) {
                            this.currentStep = 1;

                        } else {
                            this.currentStep = 2;
                        }
                    } else {
                        this.jukeboxId = response.data.id;
                        this.success = true;

                    }
                });

        }

    }

}
</script>
