<template>
    <div class="text-center"
        style="top:0;left:0;right:0;"
        :style="{ 
            height: (fullscreen ? '100%' : '33%'),
            minHeight: (fullscreen ? 'auto' : '250px'),
            position: (fullscreen ? 'absolute' : 'static') 
        }"
        :class="{
            'pb-15': fullscreen
        }"
        >
        <div id="player"
            style="max-width:100%"></div>
    </div>
</template>

<script>
import appStore from '../appStore';

export default {
    name: "JukeboxPlayer",
    props: ['fullscreen'],
    data: () => {
        return {
            jukeboxId: null,
            queueInterval: null,
            defaultVideoId: 'J4Mi0JnGfeg',
            currentTrack: null,
            player: null,
            queueId: 0,
            mobileFirstPlay: false,
            checkQueueTimer: null,
            queueResponse: {},
            playing: false,
            socket: null,
            jukebox: null
        }
    },
    watch: {
        $route(to,from) {
            if(to.name === 'jukebox-player') {
                if(!this.playing) {
                    this.jukeboxId = this.$route.params.id;
                    this.startPlayer();
                } else {
                    if(this.jukeboxId !== this.$route.params.id) {
                        this.jukeboxId = this.$route.params.id;
                        this.player.destroy();
                        this.tag.remove();
                        this.startPlayer();
                    }
                }
            }
        },
    },
    methods: {
        startPlayer() {
            var self = this;
            clearInterval(this.queueInterval);

            this.$store.data.isPlayerActive = true;

            Promise.all([
                this.$http.get('/api/tracks/tracks', {
                    'type': 'next',
                    'jukebox_id': this.jukeboxId

                }),
                this.$http.post('/api/jukebox/checkIn', {
                    'jukebox_id': this.jukeboxId
                }),
                this.$http.get('/api/jukebox/item', {
                    'jukebox_id': this.jukeboxId
                })
            ])
                .then( response => {

                    this.jukebox = response[2].data.jukebox;
                    this.queueResponse = response[0].data;

                    if(response[1].data.status === 'success') {
                        this.$store.data.jukeboxId = this.jukeboxId;
                    }


                    // 2. This code loads the IFrame Player API code asynchronously.
                    if (typeof this.tag === 'undefined') {
                        // no script tag, lets make it

                        var tag = document.createElement('script');
                        tag.src = "https://www.youtube.com/iframe_api";

                        this.tag = tag;
                        var firstScriptTag = document.getElementsByTagName('script')[0];
                        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                    } else {
                        // tag already exists, so we just need to ready up
                        onYouTubeIframeAPIReady();
                    }

                    // 3. This function creates an <iframe> (and YouTube player)
                    //    after the API code downloads.
                    window.onYouTubeIframeAPIReady = function() {
                        if (typeof window.orientation !== 'undefined') {
                            self.mobileFirstPlay = true;
                        }

                        self.playing = true;
                        self.player = new YT.Player('player', {
                            height: '100%',
                            width: '100%',
                            playerVars: {
                                'playsinline': 1
                            },
                            events: {
                                'onReady': self.onPlayerReady,
                                'onStateChange': self.onPlayerStateChange,
                                'onError': self.onPlayerError
                            }
                        });
                    }


                    this.queueInterval = setInterval(() => {
                        this.$http.get('/api/users/checkSession')
                            .then(response => {
                                if (response.data.session === false){
                                    this.$router.push('/');
                                } else {
                                    localStorage.setItem('token', response.data.token)
                                    localStorage.setItem('username', response.data.username);
                                    this.$store.data.isUser = true;
                                    this.$store.data.username = response.data.username;
                                    this.$store.data.jukeboxId = response.data.jukebox_id;
                                }
                            });

                        this.$http.get('/api/jukebox/adminActionCheck', {
                            'jukebox_id': this.jukeboxId
                        })
                            .then(response => {
                                if (response.data.status === 'success') {
                                    switch (response.data.action) {
                                        case 'skip':
                                        case 'clear':
                                            this.playNextTrack();

                                            break;
                                    }
                                }
                            });
                    },3000);
                });

        },

        onPlayerReady(event) {
            // 4. The API will call this function when the video player is ready.
            if(parseInt(this.jukebox.link_play) === 1) {
                this.startSocket()
                    .then(() => {
                        this.playTrack(this.queueResponse.track);
                    });

            } else {
                this.playTrack(this.queueResponse.track);
            }

        },

        onPlayerError(event) {

            if(this.currentTrack) {
                this.$http.post('/api/tracks/tracks',{
                    'type': 'error',
                    'track_id': this.currentTrack ? this.currentTrack.track_id : 0,
                    'error_code': event.data,
                    'jukebox_id': this.jukeboxId
                });
            }

            this.playNextTrack();

        },
        onPlayerStateChange(event) {
            /*
            -1 (unstarted)
            0 (ended)
            1 (playing)
            2 (paused)
            3 (buffering)
            5 (video cued).
            */
            switch (event.data) {
                case -1:
                    break;
                case 0:
                    this.playNextTrack();
                    break;
                case 1:
                    // playing
                    this.mobileFirstPlay = false;
                    break;
                case 2:
                    break;
                case 3:
                    break;
                case 5:
                    this.player.playVideo();
                    break;
            }
        },
        playTrack(track, clearQueueTimer) {
            var videoId = null;

            if (track == null || typeof track === 'undefined' || track === 'null') {
                this.queueId = 0;
                this.player.cueVideoById(this.defaultVideoId);
                videoId = this.defaultVideoId;
                if (this.mobileFirstPlay === false){
                    this.player.playVideo();
                }
            } else {
                this.currentTrack = track;
                this.queueId = track.queue_id;
                this.player.cueVideoById(track.video_id);
                videoId = track.video_id;
                if (this.mobileFirstPlay === false){
                    this.player.playVideo();
                }
                if(clearQueueTimer) {
                    clearTimeout(this.checkQueueTimer);
                }
            }

            if(parseInt(this.jukebox.link_play) === 1) {
                var obj = {
                    'type': 'track',
                    'jukeboxId': this.jukeboxId+'-'+this.jukebox.link_play_hash,
                    'trackId': videoId,
                    'jwt': localStorage.getItem('token'),
                }

                this.socket.send(JSON.stringify(obj));
            }

        },
        playNextTrack() {
            // track as finished playing. Get the next song
            this.$http.get('/api/tracks/tracks', {
                'type': 'next',
                'jukebox_id': this.jukeboxId,
                'queue_id': this.queueId
            })
                .then(response => {
                    this.playTrack(response.data.track);
                });

        },
        checkQueue() {
            this.$http.get('/api/tracks/tracks', {
                'type': 'next',
                'jukebox_id': this.jukeboxId
            })
                .then(response => {
                    this.playTrack(response.data.track, true);
                });
        },
        startSocket() {
            return new Promise((resolve, reject) => {

                this.socket = new WebSocket(this.socketUrl);

                this.socket.onmessage = (message)  => {
                    var data = JSON.parse(message.data);

                    switch(data.type) {
                        case 'clientJoined':
                            // send current time
                            var obj = {
                                'type': 'nowPlaying',
                                'time': this.player.getCurrentTime(),
                                'trackId': this.currentTrack.video_id,
                                'jukeboxId': this.jukeboxId+'-'+this.jukebox.link_play_hash,
                                'jwt': localStorage.getItem('token')
                            }

                            this.socket.send(JSON.stringify(obj));

                            break;
                    }
                };

                this.socket.onopen = (event) => {
                    var obj = {
                        'type': 'create',
                        'jukeboxId': this.jukeboxId+'-'+this.jukebox.link_play_hash,
                        'jwt': localStorage.getItem('token'),
                    }

                    this.socket.send(JSON.stringify(obj));
                    resolve();

                };
            });



        },
    },
    computed: {
        socketUrl() {
            return SOCKET_URL;
        }
    },
    mounted() {
    }

}
</script>
