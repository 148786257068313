<template>
    <div class="pb-15">
        <v-container fluid>
            <v-row>
                <v-col cols="8">
                    <h2>Jukebox History</h2>
                </v-col>
                <v-col class="text-right">
                    <v-btn class="secondary" :to="'/jukeboxes/my'">Back</v-btn>
                </v-col>
            </v-row>
            <p>Remove songs form this jukebox's history to prevent the tracks from playing randomly when the queue is empty.</p>
            <v-text-field v-model="filter" label="Search by Title/Artist" clearable></v-text-field>
        </v-container>


        <v-virtual-scroll
            :items="filteredTracks"
            :item-height="61"
            height="500"
        >
            <template v-slot="{ item}">
                <track-item :track="item">
                    <template v-slot:actions >
                        <v-list-item-action><v-btn icon @click="deleteTrack(item)" color="error"><v-icon>mdi-delete</v-icon></v-btn></v-list-item-action>
                    </template>
                </track-item>
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>

    </div>
</template>

<script>

    import trackItem from "../components/track.vue";

    export default {
        name: "Jukebox",
        components: {
            trackItem
        },
        data: () => {
            return {
                tracks: [],
                filter: '',
            }
        },
        methods: {
            deleteTrack(track) {

                this.$http.post('/api/jukebox/historyRemove', {
                    'jukebox_id': this.$route.params.id,
                    'track_id': track.track_id
                })
                    .then(response => {
                        var index = this.tracks.findIndex(item => item.track_id === track.track_id);

                        if (response.data.status === 'success'){
                            this.tracks.splice(index,1);
                        }
                    });


            }
        },
        computed: {
            filteredTracks() {
                if(this.filter) {
                    return this.tracks.filter(item => item.title.toLowerCase().includes(this.filter.toLowerCase()));
                }
                return this.tracks;
            }
        },
        mounted() {

            this.$store.data.loading = true;
            this.$http.get('/api/jukebox/history', {
                jukebox_id: this.$route.params.id
            })
            .then(response => {
                this.tracks = response.data.tracks;
                this.$store.data.loading = false;
            });
        }

    }
</script>
