<template>
    <div class="pb-15">
        <v-snackbar v-model="showError" top color="error" timeout="10000">
            <span v-html="errorHtml"></span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showError = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="showSuccess" top color="success" timeout="10000">
            <span>Password Updated</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showSuccess = false"
                    icon

                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-container>
            <v-row>
                <v-col cols="8">
                    <h2>Change Password</h2>
                </v-col>
                <v-col class="text-right">
                    <v-btn class="secondary" :to="'/user/'+this.$store.data.username">Back</v-btn>
                </v-col>

            </v-row>
            <v-form @submit="save" @submit.prevent v-if="!this.$store.data.loading">
                <v-text-field label="Current Password" v-model="currentPass" type="password"></v-text-field>
                <v-text-field label="New Password" v-model="newPass1" type="password"></v-text-field>
                <v-text-field label="New Password Again" v-model="newPass2" type="password"></v-text-field>

                <v-btn type="submit" color="primary">Change Password</v-btn>
            </v-form>

        </v-container>


    </div>
</template>

<script>
    export default {
        name: "ChangePassword",
        data: () => {
            return {
                errorHtml: null,
                showError: false,
                showSuccess: false,
                currentPass: null,
                newPass1: null,
                newPass2: null
            }
        },
        methods: {
            save() {
                this.showSuccess = false;
                this.showError = false;
                this.$http.post('/api/users/changePassword', {
                    'currentPass': this.currentPass,
                    'newPass1': this.newPass1,
                    'newPass2': this.newPass2
                })
                    .then(response => {
                        if (response.data.status === 'error') {
                            this.errorHtml = response.data.error;
                            this.showError = true;
                        } else {
                            this.showSuccess = true;
                            this.currentPass = '';
                            this.newPass1 = '';
                            this.newPass2 = '';
                        }
                    });

            }
        }
    }
</script>
