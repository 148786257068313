<template>
    <div>
        <v-list-item two-line>
            <v-list-item-avatar rounded size="48">
                <v-img :src="'https://img.youtube.com/vi/'+track.video_id+'/mqdefault.jpg'"></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
                <v-list-item-title v-html="trackTitle"></v-list-item-title>
                <v-list-item-subtitle v-if="artist" v-html="artist"></v-list-item-subtitle>
            </v-list-item-content>
            <slot name="actions"></slot>
        </v-list-item>
    </div>
</template>

<script>
export default {
    name: "Track",
    props: ['track', 'isAdmin'],
    data: () => {
        return {

        }
    },
    computed: {
        artist() {
            var newtitle = '';

            if(this.track.title.indexOf(' - ') !== -1) {
                newtitle = this.track.title.split(' - ');
                return newtitle[0];
            }
            return false;

        },
        trackTitle() {
            var newtitle = '';

            if(this.track.title.indexOf(' - ') !== -1) {
                newtitle = this.track.title.split(' - ');
                return newtitle[1];
            } else {
                return this.track.title;
            }

        }

    }
}
</script>
