<template>
	<div class="pb-15">
		<v-container
			v-if="!checkedIn && !this.$store.data.loading && this.pageLoaded"
			class="text-center"
			fluid
		>
			<v-icon x-large color="primary" style="font-size: 3rem"
				>mdi-marker-radius</v-icon
			>
			<h2>Check-in Required!</h2>
			<p>Check-in to a jukebox before viewing the play queue.</p>
			<v-btn block color="primary" :to="'/jukeboxes'"
				>Find a Jukebox</v-btn
			>
		</v-container>
		<div v-if="checkedIn && pageLoaded">
			<v-img
				v-if="lastPlayed && !this.$store.data.isPlayerActive"
				:aspect-ratio="16 / 9"
				:src="
					'https://img.youtube.com/vi/' +
					lastPlayed.video_id +
					'/mqdefault.jpg'
				"
				max-height="300"
			>
				<v-row align="end" class="white--text px-2 fill-height">
					<v-col class="lightbox">
						<h2 class="font-weight-thin text-truncate">
							{{ lastPlayedTitle }}
						</h2>
						<h3 class="font-weight-thin text-truncate">
							{{ lastPlayedArtist }}
						</h3>
					</v-col>
				</v-row>
			</v-img>
			<v-toolbar v-if="isAdmin" elevation="10" class="stickyTop">
				<v-row justify="space-around">
					<v-btn icon color="primary" @click="skipTrack">
						<v-icon>mdi-skip-next</v-icon>
					</v-btn>
					<v-btn
						icon
						color="primary"
						@click="
							showBanModal = true;
							banTrackId = lastPlayed.track_id;
						"
					>
						<v-icon>mdi-cancel</v-icon>
					</v-btn>
					<v-btn
						icon
						color="primary"
						:to="'/jukebox/player/' + jukeboxId"
					>
						<v-icon>mdi-play-circle</v-icon>
					</v-btn>
					<v-btn
						icon
						color="primary"
						:to="'/jukebox/edit/' + jukeboxId"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>
				</v-row>
			</v-toolbar>
			<v-list two-line class="transparent" v-if="tracks.length > 0">
				<v-subheader>
					Queued Tracks
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						small
						@click="showClearModal = true"
						v-if="isAdmin"
						>Clear</v-btn
					>
				</v-subheader>
				<component
					:is="isAdmin ? 'draggable' : 'div'"
					v-model="tracks"
					@start="drag = true"
					@end="updateTrackOrder()"
					handle=".handle"
				>
					<track-item
						v-for="track in tracks"
						:track="track"
						:key="track.id"
					>
						<template v-slot:actions>
							<v-list-item-action>
								<router-link :to="'/user/' + track.username">
									<v-avatar size="32">
										<v-img
											:src="track.avatar"
											title="track.username"
										></v-img>
									</v-avatar>
								</router-link>
							</v-list-item-action>
							<v-list-item-action v-if="isAdmin" class="ml-0">
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn icon v-bind="attrs" v-on="on"
											><v-icon
												>mdi-dots-horizontal</v-icon
											></v-btn
										>
									</template>
									<v-list color="primary">
										<v-list-item
											@click="deleteTrack(track)"
										>
											<v-list-item-title
												>Remove from
												Queue</v-list-item-title
											>
										</v-list-item>
									</v-list>
								</v-menu>
							</v-list-item-action>
							<v-list-item-action
								v-else-if="
									parseInt(track.isQueuer) === 1 &&
									parseInt(userQueueDelete) === 1
								"
								class="ml-0"
							>
								<v-btn
									fab
									x-small
									@click="deleteTrack(track)"
									color="error"
								>
									<v-icon>mdi-delete</v-icon>
								</v-btn>
							</v-list-item-action>
							<v-list-item-action v-if="isAdmin" class="ml-0">
								<v-icon class="handle" style="cursor: move"
									>mdi-reorder-horizontal</v-icon
								>
							</v-list-item-action>
						</template>
					</track-item>
				</component>
			</v-list>
			<v-container v-if="tracks.length === 0" class="text-center" fluid>
				<h2>No Tracks Queued!</h2>
				<p>Add tracks to get your song played next.</p>
				<v-btn block color="primary" :to="'/search'"
					>Find a Track</v-btn
				>
			</v-container>
		</div>
		<v-dialog v-model="showClearModal">
			<v-card>
				<v-card-title>Clear Queue?</v-card-title>

				<v-card-text>
					Are you sure you want to clear the queue?
				</v-card-text>

				<v-card-actions>
					<v-btn @click="showClearModal = false" color="secondary"
						>Close</v-btn
					>
					<v-btn @click="clearQueue" color="error">Clear Queue</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="showBanModal">
			<v-card>
				<v-card-title>Remove Track</v-card-title>

				<v-card-text>
					How do you want to remove '{{ lastPlayedTitle }}'?
				</v-card-text>
				<v-card-text>
					<v-radio-group v-model="banOption">
						<v-radio
							label="Remove from Jukebox History"
							value="1"
						></v-radio>
						<v-radio label="Ban track forever" value="2"></v-radio>
					</v-radio-group>
				</v-card-text>

				<v-card-actions>
					<v-btn @click="showBanModal = false" color="secondary"
						>Close</v-btn
					>
					<v-btn @click="banTrack" color="error">Remove Track</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import draggable from "vuedraggable";
	import trackItem from "../components/track.vue";
	import appStore from "../appStore";
	export default {
		components: {
			trackItem,
			draggable,
		},
		data: () => {
			return {
				polling: null,
				isAdmin: false,
				jukeboxId: null,
				checkedIn: false,
				pageLoaded: false,
				lastPlayed: null,
				gettingQueue: false,
				showClearModal: false,
				showBanModal: false,
				banTrackId: null,
				banOption: "1",
				tracks: [],
				drag: false,
				userQueueDelete: null,
			};
		},
		methods: {
			skipTrack() {
				this.$http.post("/api/tracks/tracks", {
					type: "skip",
					jukebox_id: this.jukeboxId,
				});
			},
			banTrack() {
				var ban = 0;

				if (this.banOption === "2") {
					ban = 1;
				}

				this.$http
					.post("/api/jukebox/historyRemove", {
						jukebox_id: this.jukeboxId,
						track_id: this.banTrackId,
						ban: ban,
					})
					.then(() => {
						this.skipTrack();
						this.showBanModal = false;
						this.banTrackId = null;
						this.banOption = "1";
					});
			},
			deleteTrack(track) {
				this.$http
					.post("/api/tracks/tracks", {
						type: "delete",
						queue_id: track.queue_id,
						jukebox_id: this.jukeboxId,
					})
					.then(() => {
						this.getQueue();
					});
			},

			clearQueue() {
				this.$http
					.post("/api/tracks/tracks", {
						type: "clear",
						jukebox_id: this.jukeboxId,
					})
					.then(() => {
						this.showClearModal = false;
						this.getQueue();
					});
			},
			getQueue() {
				if (this.gettingQueue || this.drag) {
					return;
				}
				this.gettingQueue = true;
				Promise.all([
					this.$http.get("/api/users/isCheckedIn"),
					this.$http.get("/api/tracks/tracks", {
						limit: 100,
						type: "queue",
					}),
				]).then((response) => {
					this.$store.data.loading = false;
					this.checkedIn = response[0].data.status;
					this.isAdmin = response[0].data.admin;
					this.jukeboxId = parseInt(response[0].data.jukebox_id);
					this.lastPlayed = response[1].data.last_played;
					this.tracks = response[1].data.tracks;
					this.userQueueDelete =
						response[1].data.jukebox.user_queue_delete;
					this.pageLoaded = true;
					this.gettingQueue = false;
				});
			},
			pollQueue() {
				this.getQueue();
				this.polling = setInterval(() => {
					this.getQueue();
				}, 3000);
			},
			updateTrackOrder() {
				var tracks = this.tracks.map((track) => {
					return track.queue_id;
				});
				this.$http
					.post("/api/tracks/tracks", {
						type: "reorder",
						jukebox_id: this.jukeboxId,
						tracks: tracks,
					})
					.then(() => {
						this.showClearModal = false;
						this.getQueue();
					});

				this.drag = false;
			},
		},
		computed: {
			lastPlayedArtist() {
				var newtitle = "";

				if (this.lastPlayed) {
					if (this.lastPlayed.title.indexOf(" - ") !== -1) {
						newtitle = this.lastPlayed.title.split(" - ");
						return newtitle[0];
					}
				}
				return "";
			},
			lastPlayedTitle() {
				var newtitle = "";
				if (this.lastPlayed) {
					if (this.lastPlayed.title.indexOf(" - ") !== -1) {
						newtitle = this.lastPlayed.title.split(" - ");
						return newtitle[1];
					} else {
						return this.lastPlayed.title;
					}
				}
				return "";
			},
		},
		mounted() {
			this.$store.data.loading = true;
			this.pollQueue();
		},
		destoyed() {
			clearInterval(this.polling);
		},
	};
</script>
