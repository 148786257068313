<template>
    <div class="pb-15">
        <v-container fluid>
            <v-snackbar v-model="success" top color="success" timeout="10000">
                Jukebox Updated!
                <template v-slot:action="{ attrs }">
                    <v-btn
                        v-bind="attrs"
                        @click="success = false"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <v-snackbar v-model="showError" top color="error" multi-line timeout="10000">
                <span v-html="errorHtml"></span>
                <template v-slot:action="{ attrs }">
                    <v-btn
                        v-bind="attrs"
                        @click="showError = false"
                        icon
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
            <v-row>
                <v-col>
                    <h2>Edit Jukebox</h2>

                </v-col>
                <v-col class="text-right">
                    <v-btn class="secondary" :to="'/jukeboxes/my'">Back</v-btn>

                </v-col>

            </v-row>
            <v-form @submit="save" @submit.prevent>
                <h4 class="my-5">Name & Location:</h4>

                <v-text-field label="jukebox name" placeholder="ie: joe blogg's place" v-model="title"></v-text-field>
                <location-button show-btn="true" text="Change Location" @success="setLocation"></location-button>

                <h4 class="my-5">Jukebox Details:</h4>

                <v-textarea label="Description" placeholder="(optional)" v-model="description"></v-textarea>

                <v-file-input label="Cover Image" v-model="coverImage" prepend-icon="mdi-image" placeholder="Choose a Cover Image" hint="JPEG only" persistent-hint class="mb-3"></v-file-input>

                <v-text-field label="Website" prefix="https://" placeholder=" " v-model="website"></v-text-field>

                <v-text-field label="Twitter" prefix="https://twitter.com/" placeholder=" " v-model="twitter"></v-text-field>

                <v-text-field label="Facebook" prefix="https://facebook.com/" placeholder=" " v-model="facebook"></v-text-field>

                <h4 class="my-5">Jukebox Settings:</h4>

                <v-text-field class="mb-3" label="Password Protect" placeholder="(optional)" hint="Only users with the password can add tracks." persistent-hint v-model="password">

                </v-text-field>
                <v-btn v-if="currentPassword" block class="mb-3 secondary" @click="currentPassword = ''">Remove Password</v-btn>

                <v-select
                    label="Empty Queue Setting"
                    hint="Choose which tracks are played when the queue is empty."
                    persistent-hint
                    :items="queueSettingItems"
                    v-model="emptySetting"
                    class="mb-3"
                >

                </v-select>

                <v-select
                    label="Track Selection Setting"
                    hint="Choose which tracks can be selected to play."
                    persistent-hint
                    class="mb-3"
                    :items="trackSettingItems"
                    v-model="trackSetting"
                >


                </v-select>

                <v-select
                    label="User Queue Limit"
                    hint="Limit how many tracks a user can have in the queue."
                    persistent-hint
                    class="mb-3"
                    :items="userLimitItems"
                    v-model="userQueueLimit"
                >


                </v-select>

                <v-switch
                    v-model="linkPlay"
                    :label="'Remote Play - Allow users to listen to this jukebox from other locations (jukebox must be playing)'"
                    inset
                ></v-switch>
                <v-switch
                    v-model="shufflePlay"
                    :label="'Shuffle Queue - Play queued songs in shuffle mode'"
                    inset
                ></v-switch>
                <v-switch
                    v-model="userQueueDelete"
                    :label="'Delete from Queue - Allow users to remove their queued songs from the jukebox queue'"
                    inset
                ></v-switch>


                <v-btn type="submit" color="primary" block>Save Jukebox</v-btn>

            </v-form>
        </v-container>
    </div>
</template>

<script>
import locationButton from "./components/locationButton.vue";

export default {
    name: "JukeboxEditPage",
    components: {
        locationButton
    },
    data: () => {
        return {
            success: false,
            showError: false,
            errorHtml: '',
            title: null,
            lat: null,
            long: null,
            description: '',
            coverImage: null,
            website: '',
            twitter: '',
            facebook: '',
            password: '',
            currentPassword: '',
            emptySetting: 0,
            trackSetting: 0,
            linkPlay: 0,
            userQueueLimit: 0,
            shufflePlay: 0,
            userQueueDelete: 1,
            jukeboxId: null,
            queueSettingItems: [
                {
                    text: 'Play Nothing',
                    value: 0
                },
                {
                    text: 'This Jukebox\'s History',
                    value: 1
                },
                {
                    text: 'Entire Jukebox Star Database',
                    value: 2
                }
            ],
            trackSettingItems: [
                {
                    text: 'Any Song',
                    value: 0
                },
                {
                    text: 'This Jukebox\'s History',
                    value: 1
                }
            ],
            userLimitItems: [
                {
                    text: 'No Queue Limit',
                    value: 0
                },
                {
                    text: '1 Track',
                    value: 1
                },
                {
                    text: '2 Tracks',
                    value: 2
                },
                {
                    text: '3 Tracks',
                    value: 3
                },
                {
                    text: '4 Tracks',
                    value: 4
                },
                {
                    text: '5 Tracks',
                    value: 5
                },
                {
                    text: '6 Tracks',
                    value: 6
                },
                {
                    text: '7 Tracks',
                    value: 7
                },
                {
                    text: '8 Tracks',
                    value: 8
                },
                {
                    text: '9 Tracks',
                    value: 9
                },
                {
                    text: '10 Tracks',
                    value: 10
                },
            ]
        }
    },

    methods: {
        setLocation() {
            this.lat = this.$store.data.lat;
            this.long = this.$store.data.long;
        },
        save() {
            var apiData = {
                'title': this.title,
                'geo': this.lat + ',' + this.long,
                'description': this.description,
                'image': this.coverImage,
                'website': this.website,
                'twitter': this.twitter,
                'facebook': this.facebook,
                'empty_setting': this.emptySetting,
                'selection_setting': this.trackSetting,
                'link_play': this.linkPlay ? 1 : 0,
                'user_queue_limit': this.userQueueLimit,
                'shuffle_play': this.shufflePlay ? 1 : 0,
                'user_queue_delete': this.userQueueDelete ? 1 : 0,
                'currentPassword': this.currentPassword,
                'jukebox_id': this.$route.params.id
            };
            if(this.password.length > 0) {
                apiData.password = this.password;
            }

            this.$http.post('/api/jukebox/edit', apiData)
                .then(response => {
                    if (response.data.status === 'error') {
                        this.showError = true;
                        this.errorHtml = response.data.errors;
                    } else {
                        this.success = true;
                    }
                });

        }

    },
    mounted() {

        this.$store.data.loading = true;
        this.$http.get('/api/jukebox/edit', {
            'jukebox_id': this.$route.params.id
        })
            .then(response => {
                this.title = response.data.title;
                this.description = response.data.description;
                this.lat = response.data.lat;
                this.long = response.data.lng;
                this.website = response.data.website;
                this.twitter = response.data.twitter;
                this.facebook = response.data.facebook;
                this.currentPassword = response.data.password;

                this.emptySetting = parseInt(response.data.empty_setting);
                this.trackSetting = parseInt(response.data.selection_setting);
                this.linkPlay = parseInt(response.data.link_play);
                this.userQueueLimit = parseInt(response.data.user_queue_limit);
                this.shufflePlay = parseInt(response.data.shuffle_play);
                this.userQueueDelete = parseInt(response.data.user_queue_delete);

                this.$store.data.loading = false;
            });


    }
}
</script>
