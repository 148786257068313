<template>

    <div>
        <v-snackbar v-model="showSuccess" top color="success" timeout="10000">
            <span>Track added to playlist.</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showSuccess = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="showError" top color="error" timeout="10000">
            <span>{{ errorMessage }}</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showError = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-list-item v-if="playlist">
            <v-list-item-content>
                <h2>{{ playlist.title }}</h2>
                <p v-if="playlist.description">{{ playlist.description }}</p>
            </v-list-item-content>
            <v-list-item-action class="ml-0">
                <v-menu offset-y v-if="isAdmin">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                    </template>
                    <v-list color="primary">
                        <v-list-item :to="'/search/playlistEdit/'+this.jukeboxId+'/'+this.id">
                            <v-list-item-title>Edit Playlist</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="showPlaylistDeleteModal = true">
                            <v-list-item-title>Delete Playlist</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-list-item-action>
        </v-list-item>
        <v-container fluid v-if="this.searching">
            <div class="text-center" >
                <v-icon x-large color="secondary" style="font-size:3rem">mdi-magnify</v-icon>
                <h2>Searching for tracks</h2>
                <p>Please wait</p>
            </div>
        </v-container>
        <v-container fluid v-if="this.loaded && tracks.length === 0 && !this.$store.data.loading">
            <div class="text-center" >
                <v-icon x-large color="secondary" style="font-size:3rem">mdi-close-circle-outline</v-icon>
                <h2>No Tracks Found</h2>
                <p>Please try a different search or selection.</p>
            </div>
        </v-container>

            <div :key="index" v-for="(trackItem,index) in tracks">
                <track-item :track="trackItem">
                    <template v-slot:actions >
                        <v-list-item-action>
                            <v-btn
                                fab
                                x-small
                                @click="queueTrack(trackItem)"
                                color="primary"
                                :disabled="tracksAdded.includes(trackItem.video_id)"
                                :loading="addingTrack == trackItem.video_id"
                            >
                                <v-icon
                                    v-if="!tracksAdded.includes(trackItem.video_id)"
                                >mdi-plus</v-icon>
                                <v-icon v-if="tracksAdded.includes(trackItem.video_id)">mdi-check</v-icon>
                            </v-btn>
                        </v-list-item-action>
                        <v-list-item-action v-if="isAdmin" class="ml-0">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
                                </template>
                                <v-list color="primary">
                                    <v-list-item @click="playlistModal(trackItem)" v-if="type !== 'playlist'">
                                        <v-list-item-title>Add to Playlist</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item @click="removeTrackFromPlaylist(trackItem)" v-if="type === 'playlist'">
                                        <v-list-item-title>Remove From Playlist</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-list-item-action>
                    </template>
                </track-item>
                <v-divider v-if="index !== Object.keys(tracks).length - 1"></v-divider>
            </div>

        <v-container fluid>
            <v-btn block color="primary" v-if="showMore" @click="loadMore">Load More</v-btn>
        </v-container>

        <v-dialog v-model="showPlaylistModal" v-if="isAdmin">
            <v-card>
                <v-card-title>Add to Playlist</v-card-title>

                <v-card-text>
                    <v-select :items="playlists" v-model="playlistId" item-value="playlist_id" item-text="title">

                    </v-select>
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="showPlaylistModal = false" color="secondary">Close</v-btn>
                    <v-btn @click="addTrackToPlaylist" color="primary" >Add to Playlist</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showPlaylistDeleteModal" v-if="isAdmin">
            <v-card>
                <v-card-title>Delete Playlist</v-card-title>

                <v-card-text>
                    Are you sure you want to delete this playlist?
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="showPlaylistDeleteModal = false" color="secondary">Close</v-btn>
                    <v-btn @click="deletePlaylist" color="error" >Delete Playlist</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


<script>
import trackItem from "../components/track.vue";
export default {
    name: "MusicResultsPage",
    components: {
        trackItem
    },
    props: ['isAdmin', 'jukeboxId', 'playSetting'],
    data: () => {
        return {
            loaded: false,
            type: null,
            id: null,
            limit: 30,
            offset: 0,
            tracks: [],
            showMore: false,
            tracksAdded: [],
            showPlaylistModal: false,
            showPlaylistDeleteModal: false,
            selectedTrack: {},
            playlists: [],
            playlistId: null,
            showSuccess: false,
            showError: false,
            errorMessage: '',
            playlist: null,
            addingTrack: false,
            searching: false
        }
    },
    computed: {

    },
    methods: {
        loadMore() {
            this.offset = this.offset + this.limit;
            this.getResults();

        },
        getResults() {
            var apiData = {
                limit: this.limit,
                offset: this.offset,
                type: this.type
            }

            this.$store.data.loading = true;

            switch(this.type) {
                case 'keyword':
                    apiData.keyword = decodeURIComponent(this.id);
                    apiData.type = 'search';
                    this.searching = true;

                    break;
                case 'genre':
                    apiData.genre_id = this.id;
                    break;
                case 'playlist':
                    apiData.playlist_id = this.id;
                    this.getPlaylist();
                    break;

            }

            this.$http.get('/api/tracks/tracks', apiData)
                .then(response => {

                    if(response.data.tracks) {
                        if(this.tracks.length <= 0) {
                            this.tracks = response.data.tracks;
                        } else {
                            this.tracks = this.tracks.concat(response.data.tracks);
                        }

                        if(response.data.tracks.length === this.limit && this.type !== 'keyword') {
                            this.showMore = true;
                        } else {
                            this.showMore = false;
                        }
                    }
                    this.$store.data.loading = false;
                    this.loaded = true;
                    this.searching = false;

                });

        },

        queueTrack(track) {

            this.addingTrack = track.video_id;

            this.$http.post('/api/tracks/tracks', {
                type: 'add',
                track_id: track.video_id,
                title: track.title
            })
                .then(response => {
                    this.addingTrack = false;
                    if(response.data.status === 'success'){
                        this.tracksAdded.push(track.video_id);
                    } else if(response.data.status === 'error'){
                        this.errorMessage = response.data.message;
                        this.showError = true;

                    }
                });

        },
        playlistModal(track) {
            this.selectedTrack = track;


            if(this.playlists.length > 0) {
                this.playlistId = this.playlists[0].playlist_id;
                this.showPlaylistModal = true;
            } else {
                this.$http.get('/api/jukebox/playlists', {
                    'jukebox_id': this.jukeboxId
                })
                    .then(response => {
                        this.playlists = response.data.playlists;
                        this.playlistId = response.data.playlists[0].playlist_id;
                        this.showPlaylistModal = true;
                    });

            }

        },
        addTrackToPlaylist() {

            this.$http.post('/api/jukebox/playlistTrackAdd',{
                'track_id': this.selectedTrack.video_id,
                'title': this.selectedTrack.title,
                'playlist_id': this.playlistId
            })
                .then(response => {
                    this.showPlaylistModal = false;
                    this.selectedTrack = {};
                    this.playlistId = null;
                    this.showSuccess = true;
                });

        },
        removeTrackFromPlaylist(track) {
            this.$http.post('/api/jukebox/playlistTrackRemove',{
                'track_id': track.track_id,
                'playlist_id': this.id
            })
                .then(response => {
                    var index = this.tracks.findIndex(item => item.track_id === track.track_id);
                    this.tracks.splice(index,1);
                });


        },
        getPlaylist() {
            this.$http.get('/api/jukebox/playlist',{
                'jukebox_id': this.jukeboxId,
                'playlist_id': this.id

            })
            .then(response => {
                this.playlist = response.data.playlist

            })

        },
        deletePlaylist() {
            this.$http.post('/api/jukebox/playlistRemove',{
                'playlist_id': this.id
            })
                .then(response => {
                    this.$router.push('/search');
                });

        }
    },
    watch: {
        '$route.params.id': function (id) {
            this.id = this.$route.params.id;
            this.type = this.$route.params.type;
            this.$store.data.loading = true;
            this.tracks = [];
            this.playlist = null;

            this.getResults()
        }
    },
    mounted() {
        this.$store.data.loading = true;
        this.type = this.$route.params.type;
        this.id = this.$route.params.id;

        this.getResults();
        this.$http.get('/api/tracks/tracks', {
            limit: 100,
            type: 'queue'
        })
            .then(response => {
                this.tracksAdded = response.data.tracks.map(t => t.video_id);

            });




    }
}
</script>
