<template>
    <div class="pb-15">
        <v-snackbar v-model="showError" top color="error" timeout="10000">
            <span v-html="errorHtml"></span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showError = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar v-model="showSuccess" top color="success" timeout="10000">
            <span>Profile Updated</span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showSuccess = false"
                    icon

                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-container fluid>
            <v-row>
                <v-col>
                    <h2>Edit Profile</h2>
                </v-col>
                <v-col class="text-right">
                    <v-btn class="secondary" :to="'/user/'+this.$store.data.username">Back</v-btn>
                </v-col>
            </v-row>
            <p>Everything entered here will be visible to other Jukebox Star users.</p>
            <v-form @submit="save" @submit.prevent v-if="!this.$store.data.loading">

                <v-file-input label="Avatar" v-model="avatarImage" prepend-icon="mdi-image" placeholder="Choose an Avatar" hint="Tip: Pick a square cropped image. JPEG only" persistent-hint class="mb-3" accept="image/jpeg"></v-file-input>

                <v-textarea label="Bio" v-model="bio"></v-textarea>

                <v-text-field label="Twitter" prefix="https://twitter.com/" placeholder=" " v-model="twitter"></v-text-field>

                <v-text-field label="Facebook" prefix="https://facebook.com/" placeholder=" " v-model="facebook"></v-text-field>

                <v-btn type="submit" color="primary" block>Save Profile</v-btn>
            </v-form>

        </v-container>
    </div>
</template>

<script>

export default {
    name: "EditProfile",
    data: () => {
        return {
            errorHtml: null,
            showError: false,
            showSuccess: false,
            avatarImage: null,
            bio: null,
            twitter: null,
            facebook: null
        }
    },
    methods: {
        save() {
            this.showError = false;
            this.showSuccess = false;
            this.$http.post('/api/users/edit', {
                'image': this.avatarImage,
                'bio': this.bio,
                'twitter': this.twitter,
                'facebook': this.facebook
            })
                .then(response => {
                    if (response.data.status === 'success') {
                        this.showSuccess = true;


                    } else {
                        this.showError = true;
                        this.errorHtml = response.data.error;
                    }
                });

        }
    },
    mounted() {
        this.$store.data.loading = true;
        this.$http.get('/api/users/profile')
            .then(response => {
                this.bio = response.data.user.bio;
                this.twitter = response.data.user.twitter;
                this.facebook = response.data.user.facebook;
                this.$store.data.loading = false;

            });
    }

}
</script>
