<template>
    <v-container fluid fill-height>
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="6" lg="4" >
                <v-img src="/assets/img/logo-lg.png" class="mb-5"/>
                <v-form @submit="reset" @submit.prevent>
                    <v-alert v-show="error" type="error" v-html="errorMsg"></v-alert>
                    <v-alert v-show="success" type="success" v-html="successMsg"></v-alert>
                    <v-text-field label="Your Email Address" v-model="email" autofocus></v-text-field>
                    <v-btn type="submit" block color="primary">Reset Password</v-btn>
                </v-form>
                <v-row>
                    <v-col class="text-center">
                        Already have an account? <router-link to="/Login">Login</router-link>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

    export default {
        name: "ForgotPassword",
        data: () => {
            return {
                email: '',
                error: false,
                errorMsg: null,
                success: false,
                successMsg: null
            }
        },
        computed: {

        },
        methods: {
            reset() {
                this.errorMsg = null;
                this.error = false;

                this.$http.post('/api/users/resetPassword', {
                    email: this.email

                })
                    .then(response => {

                        if (response.data.status === 'error') {
                            this.errorMsg = response.data.error;
                            this.error = true;
                        } else if (response.data.status === 'success'){
                            // show success message
                            this.successMsg = 'We\'ve sent you an email. Please check your inbox.';
                            this.success = true;

                        }
                    });

            }

        },
        mounted() {
            // execute this code when the component is 'ready'

        }
    }
</script>
