import Vue from 'vue';

const appStore = Vue.observable({
    data: {
        loading: false,
        isUser: false,
        haveLocation: false,
        lat: null,
        long: null,
        jukeboxId: null,
        username: null,
        isPlayerActive: false
    },
    methods: {
        setLocation(lat, long) {
            appStore.data.lat = lat;
            appStore.data.long = long;
            appStore.data.haveLocation = true;
            localStorage.setItem('location', JSON.stringify({lat:lat,long:long}));

        },
        loadLocation() {
            var location = localStorage.getItem('location');
            if(location) {
                var parsedLocation = JSON.parse(location);

                appStore.methods.setLocation(parsedLocation.lat, parsedLocation.long);


            }

        },
        destroySession() {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            localStorage.removeItem('location');
            appStore.data.isUser = false;
            appStore.data.haveLocation = false;
            appStore.data.lat = null;
            appStore.data.long = null;
            appStore.data.jukeboxId = null;

        }

    }
});
export default appStore;
