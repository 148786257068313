<template>
    <div v-if="!this.$store.data.loading">
        <v-container fluid>
            <v-row>

                <v-col class="col-md-3 col-sm-4 col-6" v-if="isAdmin">
                    <v-img :aspect-ratio="1/1" :src="''" class="elevation-10 rounded"
                           gradient="to top right, rgba(0,0,0,.4), rgba(0,0,0,.4)"
                           v-ripple>
                        <v-btn class="fill-height ma-0" block align="center" justify="center" :to="'/search/playlistAdd/'+jukeboxId" color="primary" height="100%">
                            <v-col class="text-center">
                                <v-icon x-large>mdi-playlist-plus</v-icon>
                                <div>New Playlist</div>
                            </v-col>
                        </v-btn>
                    </v-img>
                </v-col>

                <v-col class="col-md-3 col-sm-4 col-6" v-for="playlist in playlists" :key="playlists.playlist_id">
                    <router-link :to="'/search/playlist/'+playlist.playlist_id" class="musicBrowseLink">
                        <v-img :aspect-ratio="1/1" :src="'https://img.youtube.com/vi/'+ playlist.video_id + '/mqdefault.jpg'" class="elevation-10 rounded"
                               gradient="to top right, rgba(0,0,0,.4), rgba(0,0,0,.4)"
                               v-ripple>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-col class="text-center">
                                    <div>{{ playlist.title }}</div>
                                    <small>{{ playlist.trackTotal }} Tracks</small>
                                </v-col>
                            </v-row>
                        </v-img>
                    </router-link>
                </v-col>


                <v-col class="col-md-3 col-sm-4 col-6">
                    <router-link :to="'/search/genre/0'" class="musicBrowseLink">
                        <v-img :aspect-ratio="1/1" :src="''" class="elevation-10 rounded"
                               gradient="to top right, rgba(0,0,0,.4), rgba(0,0,0,.4)"
                               v-ripple>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-col class="text-center">
                                    <img :src="'./../assets/img/genre/trending.svg'" width="64" />
                                    <div>Recently Added</div>
                                </v-col>
                            </v-row>
                        </v-img>
                    </router-link>
                </v-col>

                <v-col class="col-md-3 col-sm-4 col-6" v-for="genre in genres" :key="genre.genre_id">
                    <router-link :to="'/search/genre/'+genre.genre_id" class="musicBrowseLink">
                        <v-img :aspect-ratio="1/1" :src="'https://img.youtube.com/vi/'+ genre.video_id + '/mqdefault.jpg'" class="elevation-10 rounded"
                               gradient="to top right, rgba(0,0,0,.4), rgba(0,0,0,.4)"
                               v-ripple>
                            <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-col class="text-center">
                                    <img :src="'./../assets/img/genre/'+genre.image" width="64" />
                                    <div>{{ genre.title }}</div>
                                </v-col>
                            </v-row>
                        </v-img>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
    export default {
        name: "MusicBrowse",
        props: ['checkedIn', 'isAdmin', 'jukeboxId', 'playSetting'],
        data: () => {
            return {
                genres: [],
                playlists: []
            }
        },
        computed: {

        },
        methods: {

        },
        mounted() {
            Promise.all([
                this.$http.get('/api/jukebox/playlists', {
                    'jukebox_id': this.jukeboxId
                }),
                this.$http.get('/api/tracks/genres')
            ])
                .then (response => {
                    this.playlists = response[0].data.playlists;
                    this.genres = response[1].data.genres;
                });


        }
    }
</script>
