<template>
    <div>
        <!--
        <v-app-bar dense app >
            <v-tabs fixed-tabs>
                <v-tab to="/jukeboxes">All</v-tab>
                <v-tab to="/jukeboxes/my">My Jukeboxes</v-tab>
            </v-tabs>
        </v-app-bar>
    -->
        <v-main app>
            <v-tabs fixed-tabs>
                <v-tab to="/jukeboxes">All</v-tab>
                <v-tab to="/jukeboxes/my">My Jukeboxes</v-tab>
            </v-tabs>
            <router-view></router-view>
        </v-main>
    </div>
</template>

<script>
    export default {
        name: "Jukeboxes",
        data: () => {
            return {
            }
        }
    }
</script>
