<template>

    <div>
        <v-snackbar v-model="showError" top color="error" timeout="10000">
            <span v-html="errorHtml"></span>
            <template v-slot:action="{ attrs }">
                <v-btn
                    v-bind="attrs"
                    @click="showError = false"
                    icon
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
        <v-container>
            <v-row>
                <v-col cols="8">
                    <h2>Create a Playlist</h2>
                </v-col>
                <v-col class="text-right">
                    <v-btn class="secondary" :to="'/search'">Back</v-btn>
                </v-col>

            </v-row>
            <p>Create a playlist to quickly find your top songs</p>
            <v-form @submit="create" @submit.prevent v-if="!this.$store.data.loading">
                <v-text-field label="Playlist Title"  v-model="title" placeholder="ie: Rock Party Anthems"></v-text-field>
                <v-text-field label="Description"  v-model="description" placeholder="(optional)"></v-text-field>

                <v-btn type="submit" color="primary">Save Playlist</v-btn>
            </v-form>

        </v-container>
    </div>
</template>


<script>
export default {
    name: "PlaylistEditPage",
    data: () => {
        return {
            showError: false,
            errorHtml: '',
            title: '',
            jukeboxId: null,
            description: ''
        }
    },
    computed: {

    },
    methods: {
        create() {

            this.$http.post('/api/jukebox/playlistEdit', {
                'title': this.title,
                'description': this.description,
                'jukebox_id': this.jukeboxId,
                'playlist_id': this.id
            })
                .then(response => {
                    if (response.data.status === 'error') {
                        this.showError = true;
                        this.errorHtml = response.data.errors;
                    } else {
                        this.$router.push('/search/playlist/'+this.id);
                    }

                });

        }
    },
    mounted() {
        this.$store.data.loading = true;
        this.jukeboxId = this.$route.params.jukeboxId;
        this.id = this.$route.params.id;

            this.$http.get('/api/jukebox/playlist',{
                'jukebox_id': this.jukeboxId,
                'playlist_id': this.id
            })
            .then(response => {

                this.title = response.data.playlist.title;
                this.description = response.data.playlist.description;
                this.$store.data.loading = false;

            });

    }
}
</script>
