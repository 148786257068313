<template>
    <v-container fluid>
    </v-container>
</template>

<script>
    export default {
        name: "Logout",
        data: () => {
            return {

            }
        },
        computed: {

        },
        methods: {

        },
        mounted() {
            // execute this code when the component is 'ready'
            this.$store.methods.destroySession();
            this.$router.push('/');
        }
    }
</script>
