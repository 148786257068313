<template>
    <v-main app>
        <v-container fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="8" md="6" lg="4" >
                    <v-img src="/assets/img/logo-lg.png" />
                </v-col>
            </v-row>
        </v-container>
        <v-list class="transparent my-0 py-0" >
            <template v-for="(option, index) in options">
                <v-list-item
                    :to="option.to"
                    :key="index"
                    link
                    v-if="!option.requireLogin || parseInt($store.data.jukeboxId) > 0">
                    <v-list-item-avatar>
                        <v-icon>{{option.icon}}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title class="primary--text">{{option.title}}</v-list-item-title>
                        {{option.subtitle}}
                    </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
            </template>
        </v-list>

        <v-container fluid>
            <v-row>
                <v-col>
                    <v-btn
                        block
                        outlined
                        color="primary"
                        href="https://www.paypal.com/donate/?hosted_button_id=NZJDD8BYS5KA2"
                        target="_blank"
                    >
                        Donate</v-btn>
                </v-col>
                <v-col>
                    <v-btn
                        block
                        outlined
                        color="primary"
                        href="https://jukeboxstar.myspreadshop.com/"
                        target="_blank"
                    >
                    Merch Shop</v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-alert
                        color="primary"
                        dark
                        icon="mdi-new-box"
                        dense
                        text
                        prominent
                    >
                        <h4>New Update: Shuffle Play, Re-Order Queue, Merch and More!</h4>
                        <p>Let's get shuffling in our Jukebox Star merch!</p>
                        <v-btn outlined color="primary" :href="'https://www.jukeboxstar.com/blog/2022/09/23/update-shuffle-play-re-order-queue-merchandise-and-more/'" target="_blank">Read More <v-icon right>mdi-open-in-new</v-icon></v-btn>

                    </v-alert>
                </v-col>
            </v-row>
            <small>v2.2</small>
        </v-container>

    </v-main>
</template>

<script>
export default {
    name: "Home",
    data: () => {
        return {
            options: []
        }
    },
    mounted() {
        // todo: loading screen - just a static jstar icon on black
        this.options = [
            {
                'icon': 'mdi-map-marker-radius',
                'title': 'Find a Jukebox',
                'subtitle': 'Find a jukebox near you where you can put your tracks on.',
                'to': '/jukeboxes',
            },
            {
                'icon': 'mdi-play-circle',
                'title': 'My Jukeboxes',
                'subtitle': 'Take control and start a jukebox for everyone to enjoy.',
                'to': '/jukeboxes/my'
            },
            {
                'icon': 'mdi-account',
                'title': 'My Account',
                'subtitle': 'Update your account details and settings.',
                'to': '/user/'+this.$store.data.username
            },
            {
                'icon': 'mdi-music',
                'title': 'Add a Track',
                'subtitle': 'Start adding music to the jukebox you are checked in to',
                'to': '/search',
                'requireLogin': true
            },
            {
                'icon': 'mdi-playlist-music',
                'title': 'View the Queue',
                'subtitle': 'View the track queue for the jukebox you are checked in to',
                'to': '/jukeboxes/my',
                'requireLogin': true
            }

        ];

    }
}
</script>
