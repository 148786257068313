<template>
    <div>
        <v-main app >
            <v-container v-if="!checkedIn  && !this.$store.data.loading" class="text-center" fluid >
                <v-icon x-large color="primary" style="font-size:3rem">mdi-marker-radius</v-icon>
                <h2>Check-in Required!</h2>
                <p>Check-in to a jukebox before searching for tracks.</p>
                <v-btn block color="primary" :to="'/jukeboxes'">Find a Jukebox</v-btn>
            </v-container>

            <v-container fluid style="position:sticky;top: 0; z-index:100;" v-if="checkedIn && playSetting !== 1 && pageLoaded">
                <v-text-field
                    v-model="keyword"
                    append-icon="mdi-magnify"
                    label="Search by Song, Artist or Album"
                    type="text"
                    solo
                    hide-details
                    class="elevation-10"
                    color="none"
                    @click:append="searchTracks"
                    @keydown.enter="searchTracks"
                ></v-text-field>
            </v-container>
            <div v-if="checkedIn">
                <router-view :checkedIn="checkedIn" :isAdmin="isAdmin" :jukeboxId="jukeboxId" :playSetting="playSetting"></router-view>
            </div>
        </v-main>
    </div>
</template>


<script>
    export default {
        name: "Music",
        data: () => {
            return {
                keyword: null,
                isAdmin: false,
                jukeboxId: null,
                checkedIn: false,
                playSetting: 1,
                pageLoaded: false
            }
        },
        computed: {

        },
        methods: {
            searchTracks() {
                // redirect to search url

                if(this.keyword) {
                    this.$router.push({
                        name: 'music-search',
                        params: {
                            'type': 'keyword',
                            'id': encodeURIComponent(this.keyword)
                        }
                    });
                }

            }


        },
        mounted() {
            this.$store.data.loading = true;

            this.$http.get('/api/users/ischeckedin')
                .then(response => {
                    this.checkedIn = response.data.status;
                    this.isAdmin = response.data.admin;
                    this.jukeboxId = parseInt(response.data.jukebox_id);

                    this.$http.get('/api/jukebox/playSetting', {
                        jukebox_id: this.jukeboxId
                    })
                    .then(response => {
                        this.playSetting = parseInt(response.data.selection_setting);
                        this.$store.data.loading = false;
                        this.pageLoaded = true;

                    })
                });
        }
    }
</script>
