<template>
    <v-container fluid>
        <p>Jukebox Star provides a platform for house parties, bars, restaurants, clubs and other get togethers to easily share the ability to play music anywhere for free.</p>
        <p>We hope that you enjoy using Jukebox Star as much as we have enjoyed building and using it too.</p>
        <p>We have a lot of big plans for the future of Jukebox Star, but would always like to hear feedback and ideas from our users.</p>
        <p>~The Jukebox Star Team</p>

    </v-container>
</template>

<script>
export default {
    name: "AboutPage",
    data: () => {
        return {
        }
    }
}
</script>
