<template>
    <v-app dark>
        <!--
        <v-snackbar top :value="showReloadDialog" :timeout="-1" color="primary">
            Update available
            <template v-slot:action="{ attrs }">
                <v-btn
                    outlined
                    v-bind="attrs"
                    @click="refreshApp"
                >
                    Update
                </v-btn>
            </template>
        </v-snackbar>
        -->
        <v-progress-linear
            :active="this.$store.data.loading"
            :indeterminate="this.$store.data.loading"
            absolute
            top
            color="primary"
            style="z-index:9999"
        ></v-progress-linear>
        <jukebox-player-page v-show="this.$store.data.isPlayerActive" :fullscreen="this.$route.name === 'jukebox-player'"></jukebox-player-page>
        <link-player-page v-show="this.$route.name === 'link-player'"></link-player-page>
        <router-view v-if="this.$route.name !== 'jukebox-player'"></router-view>
        <v-bottom-navigation fixed v-if="this.$store.data.isUser" app class="bottomNav">
            <v-btn to="/" class="cyan--text" text>
                <span class="d-xxs-none">Home</span>
                <v-icon>mdi-home</v-icon>
            </v-btn>
            <v-btn to="/queue" class="cyan--text" text :disabled="parseInt(this.$store.data.jukeboxId) === 0">
                <span class="d-xxs-none">Queue</span>
                <v-icon>mdi-playlist-music</v-icon>
            </v-btn>
            <v-btn to="/search" class="cyan--text" text :disabled="parseInt(this.$store.data.jukeboxId) === 0">
                <span class="d-xxs-none">Music</span>
                <v-icon>mdi-music</v-icon>
            </v-btn>
            <v-btn to="/jukeboxes" class="cyan--text" text>
                <span class="d-xxs-none">Jukeboxes</span>
                <v-icon>mdi-map-marker-radius</v-icon>
            </v-btn>
            <v-btn to="/more" class="cyan--text" text>
                <span class="d-xxs-none">More</span>
                <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-app>
</template>


<script>
import jukeboxPlayerPage from "./views/JukeboxPlayerPage.vue";
import linkPlayerPage from "./views/LinkPlayerPage.vue";
import update from "./mixins/update";
import Vue from "vue";
import appStore from "./appStore";
export default {
    name: "Home",
    components: {
        jukeboxPlayerPage,
        linkPlayerPage
    },
    mixins: [update],
    data: () => {
        return {
        };
    },
    mounted() {
    }
}
</script>
<style scoped>
.theme--dark.v-application {
    background-color: var(--v-background-base, #212121) !important;
    background: linear-gradient(180deg, rgb(49,49,49) 0%, rgb(34, 34, 34) 32%);
    background-attachment: fixed;
}
.theme--light.v-application {
    background-color: var(--v-background-base, white) !important;
}
</style>
