<template>
    <div>
        <v-list two-line class="transparent">
            <v-list-item :to="'/user/'+this.$store.data.username">
                <v-list-item-content>
                    <v-list-item-title>Your Account</v-list-item-title>
                    <v-list-item-subtitle>View Your Profile and Account Settings</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :href="'/blog/faq'" :target="'_blank'">
                <v-list-item-content>
                    <v-list-item-title>Help and How-to's</v-list-item-title>
                    <v-list-item-subtitle>Check out our helpful FAQs</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :to="'/more/about'">
                <v-list-item-content>
                    <v-list-item-title>About Jukebox Star</v-list-item-title>
                    <v-list-item-subtitle>Read a little message from the founders.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :href="'https://jukeboxstar.myspreadshop.com/'" :target="'_blank'">
                <v-list-item-content>
                    <v-list-item-title>Merchandise</v-list-item-title>
                    <v-list-item-subtitle>Buy some cool merch and support Jukebox Star.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :href="'https://www.paypal.com/donate/?hosted_button_id=NZJDD8BYS5KA2'" :target="'_blank'">
                <v-list-item-content>
                    <v-list-item-title>Donate</v-list-item-title>
                    <v-list-item-subtitle>Help keep Jukebox Star free by donating.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :href="'/blog/contact'" :target="'_blank'">
                <v-list-item-content>
                    <v-list-item-title>Contact Us</v-list-item-title>
                    <v-list-item-subtitle>Ask us a question or send us your feedback.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item :href="'/blog/privacy-policy'" :target="'_blank'">
                <v-list-item-content>
                    <v-list-item-title>Privacy Policy</v-list-item-title>
                    <v-list-item-subtitle>Boring legal stuff about keeping your data safe.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>
export default {
    name: "MorePage",
    data: () => {
        return {
        }
    }
}
</script>
