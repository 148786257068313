<template>
    <div>
        <v-btn block color="primary" @click="clicked" :loading="gettingLocation" v-if="this.showBtn" :disabled="success">{{ btnText }}</v-btn>
        <v-dialog v-model="error">
            <v-card>
                <v-card-title>Unable to get your location</v-card-title>

                <v-card-text>
                    Please check your device settings to ensure you allow location services. Then refresh this page.
                </v-card-text>

                <v-card-actions>
                    <v-btn @click="error = false" color="primary" >Close</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "Jukeboxes",
    props: ['text','showBtn'],
    data: () => {
        return {
            gettingLocation: false,
            error: false,
            location: null,
            success: false
        }
    },
    watch: {
        location() {

            this.$store.methods.setLocation(this.location.coords.latitude, this.location.coords.longitude);

            this.success = true;
            this.$emit('success');
        }

    },
    computed: {
        btnText() {
            if(this.success) {
                return 'Location Found!'

            } else {
                return this.text;

            }

        }

    },
    methods: {
        async clicked() {
            this.gettingLocation = true;
            try {
                this.location = await this.getLocation();
            }
            catch(e) {
                this.error = true;
            }
            finally {
                this.gettingLocation = false;
            }
        },
        async getLocation() {

            return new Promise((resolve, reject) => {
                if(!navigator.geolocation) {
                    reject('Geolocation is not available.');
                } else if (location.host === 'www.jukeboxstar.local') {
                    resolve( {
                        coords: {
                            latitude: 53.419170,
                            longitude: -1.222510
                        }
                    });
                } else {
                    navigator.geolocation.getCurrentPosition(
                        pos => {
                            resolve(pos);
                        }, error => {
                            reject('Could not get your location.')
                        }
                    );
                }
            });
        }

    },
}
</script>
