<template>
    <v-container fluid fill-height>
        <v-row align="center" justify="center" >
            <v-col cols="12" sm="8" md="6" lg="4" >
                <v-img src="/assets/img/logo-lg.png" class="mb-5"/>
                        <v-form @submit="signup" @submit.prevent>
                            <v-alert v-show="error" type="error" v-html="errorMsg"></v-alert>
                            <v-text-field label="Your Email Address" v-model="email" autofocus></v-text-field>
                            <v-text-field label="Choose a Username" v-model="username"></v-text-field>
                            <v-text-field type="password" label="Choose a Password" v-model="password"></v-text-field>
                            <v-btn type="submit" block color="primary">Create Account</v-btn>
                        </v-form>
                        <v-row>
                            <v-col class="text-center">
                                Already have an account? <router-link to="/Login">Login</router-link>
                            </v-col>
                        </v-row>
                    <div class="text-center">
                        <small>By using Jukebox Star, you are agreeing to our <a href="/blog/privacy-policy/" target="_blank">Privacy Policy</a></small>
                    </div>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

    export default {
        name: "Register",
        data: () => {
            return {
                username: '',
                password: '',
                email: '',
                error: false,
                errorMsg: null
            }
        },
        computed: {

        },
        methods: {
            signup() {
                this.errorMsg = null;
                this.error = false;

                this.$http.post('/api/users/add', {
                    email: this.email,
                    username: this.username,
                    password: this.password

                })
                    .then(response => {

                        if (response.data.status === 'error') {
                            this.errorMsg = response.data.errors;
                            this.error = true;
                        } else if (response.data.status === 'success'){

                            localStorage.setItem('token', response.data.token)
                            localStorage.setItem('username', response.data.username);
                            this.$store.data.isUser = true;
                            if(this.$route.query.redirect) {
                                this.$router.push({path: this.$route.query.redirect});
                            } else {
                                this.$router.push({path: '/'});
                            }
                        }
                    });
            }

        },
        mounted() {
            // execute this code when the component is 'ready'

        }
    }
</script>
